._carousel{
  a{
    color:$white;
    &:hover{
      text-decoration: none;
    }

    img{
      width:auto;
      max-width: none!important;
      height:100%!important;
      margin:auto;
    }
  }
}
