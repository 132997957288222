._map{
  position: relative;
  width: 100%;
  outline:1px solid $brown;
  border:8px solid $white;
  padding-bottom: 80%;
  transition: all 0.5s ease;
  @include responsive(desktop,large-desktop){
    padding-bottom: 30%;
    width:85%;
    margin-left: auto;
    margin-right: auto;
  }

  &.-hidden{
    opacity:0;
    border:0 solid;
    padding-bottom: 0;
  }

  &> iframe{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}
