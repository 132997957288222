._quote{
  padding:15px;
  background: $light-green;
  color:$brown;
  font-style: italic;
  font-size:1.8rem;
  position:relative;
  z-index:2;
  text-align: center;
  @include responsive(desktop,large-desktop){
    position:absolute;
    border:1px solid $brown;
    padding: 20px;
    font-size:2.2rem;
    width:60%;
    left:0;
    right:0;
    margin: auto;
  }
}
