._carouselSlick{

  height:100%;

  .carouselSlickSlides{
    height:100%;
  }

  a{
    &:focus{
      outline:none;
      box-shadow: none;
      border:none;
    }
  }

  .slick-slide{
    position: relative;
    &:focus{
      outline:none;
    }
    img{
      height:100%;
    }
  }
  .slick-track,
  .slick-list{
    height:100%!important;
  }
  .slick-prev,
  .slick-next{
    z-index: 2;
    transform: scale3D(0.7,2,1);
    &:before{
      font-size:50px;
      font-family: 'Roboto',sans-serif;
      font-weight: 200;
      line-height: 20px;
    }

    @include responsive(landscape){
      display:none!important;
    }
  }

  .slick-prev{
    left: 20px;
    &:before{
      content:'<';
    }
  }
  .slick-next{
    right: 30px;
    &:before{
      content:'>';
    }
  }

  .carousel-caption{
    display: block;
    position: relative;
    bottom:0;
    left:0;
    background: $black;
    width: 100%;
    height:120px;
    text-align: center;
    @include responsive(small-desktop,desktop,ipad-landscape,large-desktop){
      position: absolute;
      left:15%;
      text-align: left;
      padding: 15px;
      height:160px;
      max-width:320px;

      h2{
        font-size:30px;
      }
      h3{
        font-size:20px;
      }
    }
    @include responsive(ipad-portrait){
      h2{
        font-size:30px;
      }
      h3{
        font-size:20px;
      }
    }

    a{
      color:$white;
      &:hover{
        text-decoration:none;
      }
    }
  }
  ._data-overlay{
    display: none;
  }

  &.gallery{

    .slick-slide{
      border-right: 1px solid #fff;
      position: relative;
      width: auto!important;

      img{
        float:left;
      }
    }

    .carousel-caption{
      display:none;
    }

    ._data-overlay{
      display: block;
      position: absolute;
      padding: 10px;
      bottom:0;
      top:70%;
      width:100%;
      background: linear-gradient(to top, rgba(0,0,0,0.7), transparent);
      @include responsive(desktop,large-desktop){
        padding: 20px;
        &:hover{
          ._social,
          ._price{
            opacity:1;
          }
        }
      }
    }
    ._social{
      display:block;
      color:$white;
      list-style: none;
      width:150px;
      margin:auto;
      padding:0;
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s ease;
      top:10px;
      right:0;
      left:0;
      text-align: center;
      li{
        display:inline-block;
        margin-left:10px;
      }

      @include responsive(desktop,large-desktop){
        top:initial;
        bottom:20px;
        right:20px;
        font-size:2rem;
      }
    }
  }

  &.regular{
    .slick-list{
      height:100%;
    }
    .slick-slide{
      img{
        width:auto;
        height:calc(100% - 100px);
        @include responsive(small-desktop,desktop,ipad-landscape,large-desktop){
          height:100%;
          min-width:100%;
        }
      }

      @include responsive(ipad-portrait, desktopWide){
        img{
          width:100%;
          height:auto;
        }

        .carousel-caption{
          display: block;
          position: absolute;
          bottom:0;
        }
      }
    }
  }
}
