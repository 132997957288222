._search{
  .form{
    height:270px;
    margin-bottom: -150px;
    position: relative;


    form{
      height:120px;
      padding-top:50px;
      display:block;
      position: relative;
      z-index:10;

      input{
        border-radius:10px 0 0 10px;
        border:none;
        box-shadow: 0 0 10px transparentize($olive-green,0.7);
        height: $base;
        background: $white;
        float:left;
        width:calc(100% - 40px);
        padding-left: 15px;
        position: relative;
        z-index:2;
        &:focus{
          border:none;
          outline:none;
        }
      }

      button{
        background: $white;
        border-radius:0 10px 10px 0;
        float: right;
        width: $base;
        height: $base;
        bordeR:none;
        box-shadow: 0 0 10px transparentize($olive-green,0.7);
        color:$olive-green;
        font-size:2rem;
      }
    }

    #searchList {
      display: none;
      background-color: white;
      position: absolute;
      top: 33px;
      width: calc(100% - 40px);
      padding: 60px 15px 15px;
      border-radius: 0 0 5px 5px;

      h4{
        font-size:16px;
        margin-bottom:5px;
      }

      ul{
        list-style: none;
        margin-left:0;
        padding-left:0;

        li{
          a{
            display: block;
            padding: 5px;
            transition: background 0.3s ease;
            border-bottom:1px solid transparentize($gray, 0.5);
            &:hover{
              background:rgba(0,0,0,0.05);
              text-decoration: none;
            }
          }

        }
      }
    }
  }
  .map{
    margin-bottom: $base;
    h1{
      color:$olive-green;
      margin-bottom: 10px;
      float:left;
      ._qty,._city{
        color:$brown;
        font-weight: $regular;
      }
      @include responsive(desktop,large-desktop){
        margin-bottom: 0;
        margin-left: 7.5%;
      }
    }
  }
  ._showMore{
    text-transform: uppercase;
    p{
      text-align: center;
      color:$olive-green;
      font-size:2rem;
      span{
        font-weight:$regular;
        color:$brown;
        margin-right: 5px;
      }
    }

    a{
      display: block;
      text-align: center;
      color:$olive-green;
      font-size:2rem;
    }
  }
}