._footer{
  background: transparentize($light-green,0.7);
  border-top:1px solid transparentize($light-green, 0.7);
  padding-bottom: $base;

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ._partners{
    margin-bottom: $base;
  }

  ._footerLogo{
    text-align: center;
    li{
      display: inline-block;
      margin: 10px 10px 10px 0;

      ._BBH-large{
        width:90%;
        max-width:320px;
      }

      a{
        display: block;
        border-radius: 50%;
        color: $white;
        background:$turquoise;
        width:$base;
        height: $base;
        line-height: $base;
        font-size: $base/2;
      }
    }
  }

  ._footerNav{
    text-align: center;
    font-size: 16px;
    margin-top:$base/2;
    border-top:1px solid transparentize($turquoise,0.5);
    text-transform: uppercase;

    li{
      border-bottom:1px solid transparentize($turquoise,0.7);
    }
    a{
      color:$turquoise;
      height: $base;
      line-height: $base;
      display: block;
    }
    @include responsive(desktop,large-desktop){
      border:none;
      text-align: left;
      column-count:2;

      li{
        border:none;
      }
      a{
        height: 30px;
        line-height: 30px;
      }
    }
  }

}