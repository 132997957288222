._hotel {
  ._head {
    h1{
      font-weight: $bold;
      margin: 30px 0 0;
      color:$brown;
      @include responsive(desktop,large-desktop){
        font-size:4rem;
      }
    }
    h2{
      color: $olive-green;
    }

    ._category{
      margin-top: $base;
    }
  }
  ._top {
    position: relative;
    height: 280px;
    margin-top: $base/2;
    @include responsive(small-desktop, desktop) {
      height: 530px;
    }
    @include responsive(large-desktop) {
      margin-top: $base;
      height: 600px;
    }
    @include responsive(ipad-portrait) {
      height: 460px;
    }
    @include responsive(ipad-landscape) {
      height: 425px;
    }
  }

  ._desc{
    padding: $base $base/2;
    p{
      font-size:1.8rem;
      line-height:2.4rem;

      @include responsive(desktop,large-desktop){
        max-width:900px;
        margin:auto;
      }
    }
  }
  ._hotelInfo{
    padding: 10px;

    @include responsive(desktop,large-desktop){
      padding: $base;
    }

    .col-xs-24.col-md-8{
      h3{
        font-weight:$bold;
        color: $olive-green;
        margin-bottom: 10px;
        font-size:2.4rem;
      }
      &> ul{
        margin:0 0 20px 0;
        padding: 0 20px;
        font-size: 1.6rem;
        line-height:2.2rem;
      }
      ._features{
        color:$olive-green;
        list-style: none;
        margin: 0 0 40px 0;
        padding: 0;
        li{
          margin-bottom: 10px;
          ul{
            display: inline-block;
            width:80%;
            padding-left: 10px;
          }

          span.fa{
            font-size:1.6rem;
          }
        }

        ._logoicon{
          background: $olive-green;
          padding: 7px;
          margin-right: 10px;
          img{
            width:16px;
            height:auto;
          }
        }

        i{
          width:32px;
          text-align: center;
          margin-right: 10px;
          display: inline-block;
          font-size:2.6rem;
          vertical-align: top;
        }

        ._price{
          list-style: none;
          margin:0;
          padding:0;

          i{
            font-size:1.8rem;
            margin: 5px 3px 0 0;
            width: 13px;
          }

          li{
            display:inline-block;
            color:$olive-green;
            opacity: 0.5;
          }

          &.-low{
            li{
              &:nth-child(1){
                opacity: 1;
              }
            }
          }
          &.-mid{
            li{
              &:nth-child(1),
              &:nth-child(2){
                opacity: 1;
              }
            }
          }
          &.-high{
            li{
              &:not(:last-child){
                opacity: 1;
              }
            }
          }
          &.-top{
            li{
              opacity: 1;
            }
          }
        }
      }
    }

    ._heading{
      margin-bottom: 10px;
      text-align: center;
      color:$olive-green;
    }
  }
  ._hotels{
    padding: $base 0;
    background: transparentize($gray, 0.7);
    color:$brown;

    .moreHotels{
      margin-bottom: 20px;
    }

    ._heading{
      margin-bottom: $base;
      text-align: center;
      font-size: 2.4rem;
    }

    ._showMore{
      margin-top:20px;
      text-transform: uppercase;
      p{
        text-align: center;
        color:$olive-green;
        font-size:2rem;
        span{
          font-weight:$regular;
          color:$brown;
          margin-right: 5px;
        }
      }

      a{
        display: block;
        text-align: center;
        color:$olive-green;
        font-size:2rem;
      }
    }
  }
}