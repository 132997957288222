$canary-screen-sm-min: 320px;
$canary-screen-sm-max: 480px;
$canary-screen-md-min: 900px;
$canary-screen-md-max: 1280px;
$canary-screen-lg-min: 1440px;

/** Mixins for responsive content **/

@mixin landscape {
    @media screen and (max-width: #{$canary-screen-md-min - 1px}) {
        @content;
    }
}

@mixin landscapeWide {
    @media screen and (max-width: #{$canary-screen-sm-max}) {
        @content;
    }
}

@mixin small-desktop {
    @media screen and (min-width: #{$canary-screen-md-min}) and (max-width: #{($canary-screen-lg-min - $canary-screen-md-min) /2 + $canary-screen-md-min}){
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$canary-screen-md-min}) and (max-width: #{$canary-screen-lg-min - 1px}){
        @content;
    }
}

@mixin desktopWide {
    @media screen and (min-width: #{$canary-screen-md-max}) and (max-width: #{$canary-screen-lg-min - 1px}){
        @content;
    }
}

@mixin large-desktop {
    @media screen and (min-width: #{$canary-screen-lg-min}) {
        @content;
    }
}

/*iPad*/
/*Ipad portrait*/
@mixin ipad-portrait{
    @media only screen  and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        @content;
    }
}
/*Ipad Landscape*/
@mixin ipad-landscape{
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        @content;
    }
}

@mixin responsive($devices...) {
    @each $device in $devices {
        @if $device == landscape {
            @include landscape {
                @content;
            }
        }
        @if $device == landscapeWide {
            @include landscapeWide {
                @content;
            }
        }
        @if $device == ipad-portrait {
            @include ipad-portrait {
                @content;
            }
        }
        @if $device == ipad-landscape {
            @include ipad-landscape {
                @content;
            }
        }
        @if $device == small-desktop {
            @include small-desktop {
                @content;
            }
        }
        @if $device == desktop {
            @include desktop {
                @content;
            }
        }
        @if $device == desktopWide {
            @include desktopWide {
                @content;
            }
        }
        @if $device == large-desktop {
            @include large-desktop {
                @content;
            }
        }
    }
}
