/* Graphik Extra Light */
@import '../../../bower_components/font-awesome/scss/font-awesome.scss';
@import 'https://fonts.googleapis.com/css?family=Aguafina+Script';
@import 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i';

$primary-font: 'Roboto Condensed', sans-serif;
$secondary-font: 'Aguafina Script', cursive;

/* Font Weight */
$light: 300;
$regular: 400;
$bold: 700;

