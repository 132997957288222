._location{

  ._top {
    position: relative;
    overflow: hidden;
    height: 280px;
    padding: 0;
    @include responsive(ipad-portrait) {
      height: 320px;
    }
    @include responsive(ipad-landscape) {
      height: 425px;
    }
    @include responsive(small-desktop, desktop) {
      height: 530px;
    }
    @include responsive(large-desktop) {
      height:auto;
      min-height: 600px;
    }

    img{
      height:100%;
      width:auto;
      @include responsive(ipad-portrait,large-desktop,desktopWide){
        height:auto;
        width:100%;
      }
    }

    h2,h3{
      position: absolute;
      z-index:2;
      background: rgba(0,0,0,0.8);
      color:$white;
      padding: 10px;
    }

    h2{
      font-size:2.8rem;
      font-weight: $bold;
      left:0;
      bottom:60px;
      @include responsive(desktop,large-desktop){
        font-size:4.8rem;
        left:20%;
        bottom:120px;
      }
    }
    h3{
      font-size:2rem;
      color: $light-green;
      left:30%;
      bottom:20px;
      @include responsive(desktop,large-desktop){
        font-size:3.5rem;
        left:30%;
        bottom:70px;
      }
    }
  }
  ._locationInfo{
    position: relative;
    padding: $base 0;
    p{
      padding: 0 20px;
      font-size:1.8rem;
      line-height:2.4rem;
      margin-bottom: $base/2;
    }
    @include responsive(desktop,large-desktop){
      padding-top: $base*3;
    }
    ._quote{
      top:-50px;
    }
  }
  ._map{
    margin-bottom: $base;
  }
  ._hotels{
    padding: $base 0;
    ._heading{
      text-align: center;
      color:$olive-green-dark;
      margin-bottom: 10px;
    }
  }
  ._showMore{
    margin-top:20px;
    text-transform: uppercase;
    p{
      text-align: center;
      color:$olive-green;
      font-size:2rem;
      span{
        font-weight:$regular;
        color:$brown;
        margin-right: 5px;
      }
    }

    a{
      display: block;
      text-align: center;
      color:$olive-green;
      font-size:2rem;
    }
  }
}
