h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: $primary-font;
  font-weight: $light;
  margin: 0;
  text-transform: uppercase;
}

.-bold{
  font-weight:$bold;
}
.-light{
  font-weight: $light;
}

a{
  color:$text-highlight;
}