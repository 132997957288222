._home{

  ._top {
    position: relative;
    height: 280px;
    padding: 0;
    @include responsive(small-desktop, desktop) {
      height: 530px;
    }
    @include responsive(large-desktop) {
      height:auto;
      min-height: 600px;

      ._carouselSlick{
        .slick-list{
          height:auto!important;
        }
        .slide{
          img{
            height:auto;
            width:100%;
          }
        }
      }
    }
    @include responsive(ipad-portrait) {
      height: 460px;
    }
    @include responsive(ipad-landscape) {
      height: 425px;
    }
  }

  ._search{
    ._search-title{
      font-size:2rem;
      line-height: $base;
      color:$olive-green-dark;
      white-space: nowrap;
      letter-spacing: -0.2px;

      @include responsive(ipad-landscape,small-desktop,desktop,large-desktop){
        font-size:2.8rem;
        text-align: right;
      }
      @include responsive(ipad-portrait){
        font-size:2.8rem;
        text-align: left;
      }
    }
    .form{
      form{
        padding-top: 0;
      }
    }
  }

  ._mid{
    padding: $base 0;
    @include responsive(desktop,large-desktop) {
      padding: $base*2 0;

      .container{
        & > .row{
          padding: 0 8px;
        }
      }
    }
  }
  ._newsletter{
    padding-top: 10px;
    background: $olive-green;
    color:$white;
    height: 220px;
    position: relative;

    @include responsive(desktop,large-desktop){
      height:310px;
    }

    p.h2{
      float:left;
      @include responsive(desktop,large-desktop){
        float:none;
      }
    }
    ._emblem{
      width:100px;
      height:100px;
      margin:10px 0;
      float: right;
      @include responsive(desktop,large-desktop){
        float:none;
      }
    }
    form{
      position: absolute;
      bottom:0;
      left:0;
      width: 100%;
      display: block;
      min-height:120px;
      label{
        text-transform: uppercase;
        color:$brown;
        font-weight: $regular;
        font-size:1.8rem;
        display: block;
        position: absolute;
        bottom:$base*2;
        left:8px;
        line-height:16px;
      }
      input{
        position: absolute;
        left:0;
        padding: 0 15px;
        height:$base;
        display: block;
        border:none;
        width: 100%;
        bottom: $base;
        font-size:1.8rem;
        color:$brown;
        &:focus{
          outline:none;
          border:none;
        }
      }
      button{
        position: absolute;
        border:none;
        background: transparent;
        width: 100%;
        bottom:0;
        left:0;
        height: $base;
        display:block;
        text-align: right;
        padding-right: 10px;
        font-size:2rem;
        text-transform: uppercase;
        font-weight:$regular;
        transition: background 0.5s ease;
        &:hover{
          background: rgba(0,0,0,0.1);
        }
      }
    }
  }
  ._hotel{
    height:auto;
    overflow: hidden;
    position: relative;
    padding: 0;
    @include responsive(landscape) {
      margin-bottom:10px;
    }
    @include responsive(desktop,large-desktop) {
      height: 310px;
      margin-bottom:8px;
      border-left:8px solid transparent;

      &:nth-of-type(5),
      &:nth-of-type(6){
        border-right:8px solid transparent;
        border-left:none;
      }

      .img-container{
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
      }
    }
    img{
      height:auto;
      width:100%;
      @include responsive(desktop,large-desktop) {
        width:auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        min-width: 50%;
        min-height: 50%;
        overflow-x: hidden;
      }
    }
    ._hotelData{
      position: absolute;
      width:100%;
      z-index: 2;
      top:0;
      left:0;
      right:0;
      bottom:0;
      margin:auto;
      padding: 15px;
      background: linear-gradient(to bottom right, rgba(0,0,0,0.8) 30%, transparent 60%);
      color:$white;

      h2{
        width:70%;
        font-weight: $bold;
        @include responsive(desktop,large-desktop) {
          width:35%;
        }
      }
    }
    &.-new{
      z-index:2;
    }
  }
  ._view-more{
    background: $light-green;
    color:$brown;
    height:140px;
    padding-top: 10px;
    a{
      display: block;
      color:$olive-green;
      font-size:2.4rem;
      padding: 5px;
      border:1px solid;
      border-radius:3px;
      width: $base;
      text-align: center;
      margin-top: 8px;
    }
    @include responsive(desktop,large-desktop) {
      height:310px;
    }
  }
  ._benefits{
    background: $light-green;

    ul {
      display:flex;
      flex-wrap: wrap;
      li{
        flex-basis: 300px;
        color:$brown;
        border-top:10px solid $white;
        padding:10px 0;

        h2{
          text-transform: none;
          font-weight: $bold;
          margin-bottom: 7px;
        }

        p{
          font-size:1.6rem;
        }

        &:first-child,
        &:last-child{
          border-top:none;
        }
        &:first-child{
          text-align: center;
          img{
            width:100px;
          }
        }
        &:last-child{
          h4{
            font-size:2rem;
          }
          a{
            display: block;
            color:$olive-green;
            font-size:2rem;
            padding: 2px;
            border:1px solid;
            border-radius:3px;
            width: $base - 10px;
            text-align: center;
            margin-top: 8px;
          }
        }
      }
      @include responsive(ipad-portrait) {
        li{
          flex-basis: 33.2%;
          margin-right: 1px;
          padding-bottom: 0;

          div{
            border-right:1px solid $white;
            padding: 10px;
          }

          &:first-child{
            text-align: center;
            img{
              width:100px;
              margin: 15px 0;
            }
          }
          &:last-child{
            padding: 15px;
            flex-grow: 2;
            h4{
              float: left;
              line-height: 45px;
              margin-right: 10px;
              br{
                display:none;
              }
            }
            a{
              float:left;
            }
          }
        }
      }

      @include responsive(desktop,large-desktop) {
        flex-wrap: nowrap;
        margin-bottom: 0;
        li{
          flex-basis: 20%;
          margin-right: 1px;
          padding-bottom: 0;

          div{
            border-right:1px solid $white;
            padding: 10px;
          }

          &:first-child{
            text-align: center;
            img{
              width:130px;
              margin: 15px 0;
            }
          }
          &:last-child{
            padding: 15px;
            h4{
              font-size:1.6rem;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  ._promos{
    padding: $base 0;
    ul{
      li{
        margin-bottom: 10px;
        @include responsive(desktop,large-desktop) {
          margin-bottom: 0;
        }
        ._emotions{
          margin:auto;
        }
      }
    }
  }

  ._locations{
    padding: $base 0;

    ul{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      li{
        flex-basis: 140px;
        margin-bottom: 16px;
        position: relative;
        @include responsive(desktop,large-desktop) {
          flex-basis: 280px;
          flex-wrap: nowrap;
        }
      }
    }
    ._locationData{
      position: absolute;
      width:100%;
      z-index: 2;
      left:0;
      bottom:0;
      margin:auto;
      padding: 15px;
      background: linear-gradient(to top, rgba(0,0,0,0.6) 40%, transparent);
      color:$white;
      max-width: 280px;
      @include responsive(desktop,large-desktop) {
        padding-top:20%;
      }
      a{
        color:$white;
        &:hover{
          text-decoration: none;
        }
      }
    }

    h2._title{
      width: 300px;
      margin: 0 auto 50px;
      font-size: 2.8rem;
      line-height: 2.6rem;
      color: $olive-green;
      padding: 0 6px;
      ._emblem{
        float:right;
        display: inline-block;
        margin-top:-50px;
        width:100px;
        height:100px;
      }
      .-bold{
        font-size:1.22em;
      }
    }

    ._more-locations{
      display: block;
      padding:5px 0;
      font-weight: $bold;
      text-align: center;
      background: $white;
      color: $olive-green;
      border:1px solid $olive-green;
      font-size:1.8rem;
      margin-top:20px;
      text-decoration: none;
      position: relative;
      i{
        font-size:2.8rem;
        margin-right: 10px;
      }
      @include responsive(desktop,large-desktop) {
        line-height: $base;
        br{
          display:none;
        }
      }
    }

    @include responsive(desktop,large-desktop) {
      padding: $base*2 0;
    }
  }
}
