.form{

  ._input{
  }


  ._checkbox {

    margin-left: -4px;

    [type="checkbox"]{
      &:not(:checked),
      &:checked{

        position: absolute;
        left: -9999px;

        & + label{
          position: relative;
          padding-left: 25px;
          cursor: pointer;
          line-height:20px;
          font-weight:300;
          font-size: 1.4rem;

          &:hover{
            color: #333;
            &::before{
              border: 1px solid #333;
            }
          }

          &::before{
            content: '';
            position: absolute;
            left:0; top: 2px;
            border: 1px solid;
            background: transparent;
            border-radius: 0;
          }

          &::after{
            content: '';
            position: absolute;
            top: 5px;
            left: 3px;
            transition: all .2s;
          }

        }

      }

      &:not(:checked) + label:after{
        opacity: 0;
        transform: scale(0);
      }
      &:checked + label:after{
        opacity: 1;
        transform: scale(1);
      }

      &:disabled:not(:checked) + label:before,
      &:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }
  }

  ._radio  {

    margin-left: -4px;

    [type="radio"] {

      &:not(:checked),
      &:checked {

        position: absolute;
        left: -9999px;

        & + label {
          position: relative;
          padding-left: 25px;
          cursor: pointer;
          line-height: 20px;
          font-weight: 300;

          &:hover {
            color: #fff;
            &::before {
              border: 1px solid #fff;
            }
            &::after{
              background:#fff;
            }
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            border: 1px solid;
            background: transparent;
            border-radius: 50%;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 3px;
            transition: all .2s;
            border-radius: 50%;
          }
        }
      }
      &:not(:checked) + label:after{
        opacity: 0;
        transform: scale(0);
      }
      &:checked + label:after{
        opacity: 1;
        transform: scale(1);
      }
      &:disabled:not(:checked) + label:before,
      &:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }
  }

  .form-group{
  }

}


