/* Variables */
$olive-green:#AB9C6A;
$olive-green-dark:#80734A;
$light-green:#E0EFCA;
$dark-green:#413A24;
$brown: #533019;
$turquoise:#33C5D8;
$black:#111;
$white:#fff;
$gray:#CCC7B4;
$dark-gray:#444;


$background: $white;
$bg-olive: $olive-green;
$bg-green: $light-green;
$text-color: $dark-gray;
$text-title-main:$brown;
$text-title-sub:$olive-green-dark;
$text-highlight:$turquoise;
