// Normalize Styles
@import 'node_modules/normalize.css/normalize';

@import 'mixins/main';
@import 'modules/main';
@import 'modules';
@import 'pages';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.yeogurt-info {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.version {
  font-size: 14px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.logo {
  margin-top: 15px;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

body, html{
  height:100%;
  cursor: default;
  color: $text-color;
}
html{
  font-size:10px;
}
body{
  font: $light 1.4rem $primary-font;
}

.wrapper{
  position: relative;
  min-height: 100%;
  padding-top: 40px;
  @include responsive(desktop, large-desktop){
    padding-top:120px;
    padding-bottom: 478px;
  }
}

footer{
  position: relative;
  width: 100%;
  @include responsive(desktop, large-desktop){
    position:absolute;
    bottom:0;
  }
}

._legal-text{
  max-width:800px;
  margin:auto;
  padding:$base;

  @include responsive(ipad-portrait,ipad-landscape,small-desktop){
    padding:$base $base*2;
  }

  p{
    text-align: justify;
  }

  p,h2,h1{
    margin-bottom:10px;
  }

  h1,h2{
    color:$olive-green-dark;
  }

  &.-about{
    p,h2,h1{
      margin-bottom:15px;
    }

    h1,h2{
      padding-bottom:10px;
      border-bottom:1px solid;
    }
  }
}

.aboutUs-heading{
  padding: $base 0;
  ._emblem{
    display:block;
    width:80px;
    height:80px;
    margin:auto;
    @include responsive(ipad-portrait,ipad-landscape,small-desktop){
      width:120px;
      height:120px;
    }

    @include responsive(desktop, large-desktop){
      width:150px;
      height:150px;
    }
  }
}

._social{
  display:none!important;
}