._header{
  position: absolute;
  top:0;
  width: 100%;
  height:$base;
  background: $bg-olive;
  z-index: 100;
  @include responsive(desktop,large-desktop){
    height:$base*3;
  }

  ._logo{
    width:$base*1.5;
    height:$base*1.5;
    background: $light-green;
    position: absolute;
    left:8px;
    text-align: center;
    line-height:$base*1.5;
    z-index: 2;

    img{
      display: inline-block;
      height: $base;
      width:auto;
    }
    @include responsive(desktop,large-desktop){
      width:$base*2.5;
      height:$base*2.5;
      line-height:$base*2.5;
      left:8px;
      top:$base*0.5;
      img{
        height:$base*2;
      }
    }
  }

  ._slogan{
    font-family: $secondary-font;
    float:left;
    font-size:21px;
    line-height: 40px;
    color:$white;
    margin: auto;
    width:100%;
    text-align: center;
    padding-top: 3px;
    text-transform: none;
    @include responsive(desktop,large-desktop){
      width:auto;
      color:$olive-green;
      text-align: left;
      margin-left: 110px;
      font-size:32px;
      margin-top:30px;
    }
  }

  ._top{
    height:40px;
    @include responsive(desktop,large-desktop){
      height:80px;
      background: $white;
    }
  }

  ._bottom{
    max-height:0;
    width: 100%;
    overflow-y:hidden;
    overflow-x:hidden;
    background: $bg-olive;
    transition: max-height 0.5s ease, padding 0.3s ease;
    position: relative;
    top:-3px;
    @include responsive(desktop,large-desktop){
      min-height: inherit;
      max-height: inherit;
      height:$base;
      top:0;
      overflow: visible;
    }
  }

  ._mainNav,._subNav,._auxiliaryNav{
    list-style: none;
    margin:auto;
    padding: 0;
    width:calc(100% - 20px);
    font-weight: $regular;
    font-size:18px;
    text-transform: uppercase;
    a{
      color:$white;
      display:block;
      height:40px;
      line-height: 40px;
      text-align: right;
      border-bottom:1px solid transparentize($light-green,0.7);
      padding-right: 20px;
      transition: all 0.3s ease;

      &:hover{
        text-decoration: none;
        @include responsive(desktop,large-desktop){
          background: rgba(0,0,0,0.1);
        }
      }
      &:focus{
        text-decoration: none;
      }
    }
    @include responsive(desktop,large-desktop){
      width: auto;
      li{
        float:left;
      }
      a{
        padding:0 10px;
        text-align: center;
        transition: background 0.3s;
      }
    }
  }

  ._mainNav{
    margin-top:15px;
    @include responsive(desktop,large-desktop){
      margin-top:0;
      padding-left: $base*2.5;
      float: left;
      display: flex;
      max-width: 55%;
      position: relative;
    }
    @include responsive(small-desktop){
      max-width: 65%;
    }
    @include responsive(ipad-landscape){
      max-width: 70%;
    }
  }
  ._subNav{
    a{
      color:$light-green;
    }
    @include responsive(desktop,large-desktop){
      float: right;
      text-align: right;
      margin-right: -8px;
      li{
        display: inline-block;
        float: none;
        border-right:1px solid $light-green;
        &:first-child{
          border-left:1px solid $light-green;
        }
      }
    }
    @include responsive(ipad-landscape){
      position: absolute;
      top:$base;
      right:0;
      max-height: 0;
      transition: max-height 0.3s ease, padding-bottom 0.3s ease;
      overflow: hidden;
      background-color: $olive-green;
      padding-bottom: 0;

      li{
        float:none;
        display: block;
        border-left:none;
        border-right:none;
        &:first-child{
          border-left:none;
        }
      }
    }
  }
  ._auxiliaryNav{
    text-align: center;
    margin-top:15px;
    li{
      display: inline-block;
      width:40px;
      a{
        border: none;
        font-size: 20px;
        text-align: center;
        padding: 3px 4px;
        display: inline-block;
        height: 28px;
        line-height: 20px;
        &:first-child{
          margin-right: 10px;
        }
        &.selected{
          border:1px solid;
          &:hover{
            cursor: default;
          }
        }
        &:hover{
          cursor:pointer;
        }
      }
      &.languageSelector{
        width:100px;
      }
    }
    @include responsive(desktop,large-desktop){
      float:right;
      width:160px;
      margin-top:30px;
      text-align: right;
      li{
        float: none;
        a{
          color:$olive-green;
        }
      }
    }
  }

  .-topLevel{
    &.-open{
      background: rgba(0,0,0,0.1);
      &+ ._subMenu{
        max-height:500px;
        border-bottom: 1px solid transparentize($light-green,0.7);
        @include responsive(desktop,large-desktop){
          max-height:200px;
        }
      }

      @include responsive(desktop, large-desktop){
        position: relative;
        &:after{
          content: '';
          display:block;
          position: absolute;
          bottom:-2px;
          left:0;
          right:0;
          margin:auto;
          width:10px;
          height:7px;
          border-bottom: 7px solid $white;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
        }
      }
    }
  }

  ._subMenu{
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: hidden;
    padding-top: 0;

    ._name{
      font-weight: $light;
      color:transparentize($white,0.2);
    }

    @include responsive(desktop,large-desktop){
      background: $white;
      position: absolute;
      left:92px;
      right:0;
      ul{
        max-height:$base*2;
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        li{
          flex-basis: 25%;
        }
      }

      ._name{
        font-weight: $light;
        color:transparentize($olive-green-dark,0.2);
      }
    }
  }

  .hamburguer {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    cursor: pointer;

    span{
      display: block;
      position: absolute;
      height: 1px;
      width: 30px;
      background: $white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      &:nth-child(1){
        top: 13px;
      }
      &:nth-child(2),
      &:nth-child(3){
        top: 20px;
      }
      &:nth-child(4){
        top: 27px;
      }
    }

    &.-open{
      span{
        &:nth-child(1){
          top: 18px;
          width: 0;
          left: 50%;
        }
        &:nth-child(2){
          transform: rotate(45deg);
        }
        &:nth-child(3){
          transform: rotate(-45deg);
        }
        &:nth-child(4){
          top: 18px;
          width: 0;
          left: 50%;
        }
      }

      &+ ._bottom{
        max-height:1000px;
        padding-bottom: 10px;
        transition: max-height 0.5s ease, padding 0.3s ease;
        overflow-x: hidden;
        overflow-y: visible;
      }
    }
    &.-ipad{
      display:none;
      @include responsive(ipad-landscape){
        display:block;
        &.-open{
          &+ ._subNav{
            max-height:300px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
