._benefitBanner{
  display:flex;
  border:1px solid $olive-green;
  padding: 15px;
  background:$white;
  width:100%;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.3s ease;
  min-height: 120px;

  @include responsive(landscapeWide){
    margin-bottom: 15px;
    flex-flow: column;
    ._medal,.city-logo{
      margin-bottom: 15px;
      margin-right: 0;
    }
    ._text{
      text-align:center;
    }
    ._title{
      margin-bottom: 10px;
      margin-right:0;
    }
  }

  @include responsive(ipad-portrait){
    margin-bottom: 15px;
  }
  @include responsive(ipad-landscape) {
    min-height: 190px;
  }

  ._medal,.city-logo{
    flex-basis: 80px;
    margin-right: 10px;
    margin-bottom:15px;
    @include responsive(ipad-portrait,desktop,large-desktop){
      margin-bottom:0;
    }
    @include responsive(ipad-landscape){
      margin-bottom:15px;
    }
  }

  ._medal {
    width: 80px;
    height: 80px;
  }

  .city-logo {
    width: 80px;
    height: auto;
    @include responsive(ipad-landscape) {
      width: 110px;
    }
  }

  ._text{
    display: flex;
    flex-flow: column;
    font-size:1.6rem;
    font-weight:$light;
    color:$text-color;
    align-items: center;
  }

  &.-city{
    ._text{
      flex-flow: row;
      color:$olive-green;
      font-size:1.9rem;
      @include responsive(landscape){
        br{
          display:none;
        }
      }
    }

    ._title{
      padding-right: 20px;
      border-right:1px solid $olive-green;
      line-height:2.1rem;
      letter-spacing: -1px;
    }

    ._description{
      text-transform: uppercase;
      line-height:2.1rem;
    }

    .city-logo{
      margin-left:0;
      margin-right:20px;
    }

    ._moreInfo{
      margin-left:20px;
      span{
        &:first-child{
          display: inline-block;
          color:$olive-green;
          font-size: 2rem;
          padding: 2px;
          border: 1px solid;
          border-radius: 3px;
          width: 30px;
          text-align: center;
          margin:0 5px 0 0;
          @include responsive(landscape){
            margin-top:8px;
          }
        }
        &:last-child{
          padding-bottom: 9px;
        }

      }

    }
    @include responsive(landscapeWide){
      ._text{
        flex-flow: column;
      }
      ._title{
        border-right:none;
        border-bottom:1px solid $olive-green;
        padding-bottom: 10px;
        padding-right: 0;
        margin-right: 0;
      }
    }

    @include responsive(ipad-landscape,small-desktop){
      ._title{
        line-height:2.1rem;
      }

      ._description{
        text-transform: uppercase;
        line-height:2.1rem;
      }
    }
  }

  ._title{
    font-weight:$regular;
    margin-right:20px;
  }

  &:hover{
    text-decoration: none;
    background: transparentize($olive-green, 0.9);
  }
}
